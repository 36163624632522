<template>
  <div>
    <section class="home-section">
      <header>
        <div class="header-inner-wrapper">
          <div class="elemento-right">
            <img src="/assets/imagens/glintt_logo_footer.svg" alt="logotipo" />
          </div>
        </div>
      </header>
      <main>
        <div class="main-inner-wrapper">
          <div class="font-grande-home elemento-left light-gray">
            Recuperar Password
          </div>
          <div class="elemento-center">
            <form class="form-login">
              <div class="form-linha">
                <div
                  class="input-item item-width-100 input-com-icone user-icone"
                >
                  <input
                    v-model="username"
                    id="username"
                    type="text"
                    value=""
                    placeholder="Nome de utilizador"
                    name="NomeUtilizador"
                    class="more-padding-top-bottom"
                  />
                </div>
              </div>
              <div class="form-linha">
                <div
                  class="input-item item-width-100 input-com-icone password-icone"
                >
                  <input
                    v-model="email"
                    id="email"
                    type="text"
                    value=""
                    placeholder="Email"
                    name="email"
                    class="more-padding-top-bottom"
                  />
                </div>
              </div>

              <div class="form-linha">
                <div id="resetResult">Introduza o seu username e email</div>
              </div>

              <div class="form-linha">
                <div class="button-wrapper">
                  <button
                    id="button-submit"
                    class="button diagonal font-weight-500 text-uppercase"
                    @click="resetPassword()"
                  >
                    Recuperar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    </section>
  </div>
</template>

<script
  type="text/javascript"
  src="@/assets/ficheiros/jquery-3.4.1.min.js"
></script>
<script type="text/javascript" src="@/assets/ficheiros/swiper.min.js"></script>

<script>
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'

  export default {
    name: "resetPassword",
    data() {
      return {
        user_id: "",
        name: "",
        email: "",
        username: "",
        user_level: "",
      };
    },
    methods: {
      resetPassword: async function () {
        event.preventDefault();

        var campoResposta = document.querySelector("#resetResult");
        campoResposta.innerHTML = "A recuperar password...";

        var username = this.username;
        var email = this.email;

        var dados = {
          username,
          email,
        };

        console.log(dados, "dados");

        var result = await this.resetPassAPI(dados);

        if (result.resultado == "OK") {
          // console.log(result.user_level)
          campoResposta.innerHTML =
            "Estamos a enviar sua nova password por email...";

          var email = this.email;
          var new_pass = result.new_pass;

          var dadosMail = {
            email,
            new_pass,
            new_pass_mail: 1,
          };

          var emailResult = await this.sendNewPass(dadosMail);

          if (emailResult.resultado == "OK") {
            campoResposta.innerHTML =
              "Já está! A sua nova password foi enviada por email<br>";
          } else {
            campoResposta.innerHTML =
              "Alguma coisa correu mal a enviar a sua password... Por favor volte a tentar mais tarde";
          }
        } else {
          campoResposta.innerHTML = result.details;
        }

        // displayResult.innerHTML = result.resultado
      },
      resetPassAPI: async function (apiBody) {
        try {
          var response = await fetch(
            "https://bydservices.pt/glintt-api/public/api/user/resetPassword",
            {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify(apiBody, null, 2),
            }
          );
          var result = await response.json();
          return result;
        } catch (error) {
          console.error("Error:", error);
          return "Error: " + error;
        }
      },
      sendNewPass: async function (apiBody) {
        try {
          var response = await fetch(
            "https://bydservices.pt/glintt-mailer/index.php",
            {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify(apiBody, null, 2),
            }
          );
          var result = await response.json();
          console.log(result, "result");
          return result;
        } catch (error) {
          console.error("Error:", error);
          return "Error: " + error;
        }
      },
    },
    mounted() {
      let isLogged = window.sessionStorage.getItem("user_id");
      //const result = isLogged == null ? false : true

      if (isLogged != null) {
        window.location.href = "/intro";
      }

      $(document).ready(function () {
        $("#user-area-close").click(function () {
          $(".home-section-1").addClass("scrolled");
        });

        var $imagensWrapper = $(".seccao-promo-code .imagens-wrapper");

        var galleryThumbs = new Swiper(".gallery-thumbs", {
          spaceBetween: 10,
          slidesPerView: 5,
          freeMode: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
        });

        //Slider
        var SliderPromoCode = new Swiper(
          ".main-coluna-imagens .swiper-container",
          {
            slidesPerView: 1,
            slidesPerGroup: 1,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },

            thumbs: {
              swiper: galleryThumbs,
            },
          }
        );
      });
    },
    updated() {
      let isLogged = window.sessionStorage.getItem("user_id");
      //const result = isLogged == null ? false : true

      if (isLogged != null) {
        window.location.href = "/intro";
      }
    },
  };
</script>

<style src="@/assets/home.css"></style>
<style src="@/assets/responsive.css"></style>
<style src="@/assets/ficheiros/swiper.min.css"></style>
<style src="@/assets/ficheiros/font-awesome.min.css"></style>

<style scoped>
  .home-section-1 .home-section-1-inner-wrapper .home-section-1-bg:before {
    left: -19% !important;
  }
  .button-wrapper {
    margin-top: 5% !important;
  }
  .form-login .button {
    margin-top: 0% !important;
  }
  #logiResult {
    line-height: 20px;
    min-height: 20px;
    text-align: center;
    color: #444;
    font-size: 14px;
    margin-top: 5%;
  }
  .bebas {
    font-family: "BebasNeue" !important;
  }
  #entrar:hover {
    font-weight: bold;
    cursor: pointer;
  }

  .home-section main {
    height: calc(100vh - 70px);
    /* padding-top: 100px; */
    display: flex;
    align-items: center;
  }
  .home-section main .main-inner-wrapper {
    margin-top: unset;
    width: 100%;
  }

  input[type="checkbox"].custom-checkbox ~ label:after {
    content: "X";
    top: -4px;
  }
  .links-extra .link-item:hover {
    cursor: pointer;
  }
  .form-login {
    z-index: 9999;
  }

  .scrolled {
    display: none;
  }

  #button-submit:hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 640px) {
    .home-section-1 .home-section-1-inner-wrapper .home-section-1-bg:before {
      display: none;
    }
    #seccao-servicos #user-area .home-section-1-bg {
      height: 100vh;
      width: 100vw;
      top: 0;
    }
    #form-inner {
      position: absolute;
      width: calc(100vw - 30px);
    }
    #log-out {
      position: fixed;
      bottom: 15px;
      right: 15px;
      color: #fff;
    }
    #user-area {
      position: fixed;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      z-index: 999;
    }
    #user-area-inner-form {
      width: 100vw;
      height: 100vh;
    }
    #form-fields {
      width: calc(100% - 20px);
    }

    .row {
      flex-direction: column;
      width: 90%;
    }
    .field {
      min-width: 100% !important;
      margin-left: 0px !important;
    }
    .row input {
      width: 100% !important;
    }
  }
</style>
